/* eslint-disable no-loop-func */
import React, { Component } from "react";
import Geocode from "react-geocode";
import { Link, Route, Switch } from "react-router-dom";
import { get_vehicle_type } from "../../../actions/addPostActions";
import { remove_credit_details } from '../../../actions/admin/applicationActions';
import {
  get_addtional_type, get_application_agents, get_application_detail,
  get_manual_application_detail, get_stock_id_detail, get_vehicle_make, get_vehicle_model,
  get_vehicle_trims,
  update_application_detail, update_application_detail_complete
} from "../../../actions/dealer/dealerApplicationActions";
import "../../../assets/css/adminEditApplication.css";
import AssetDetail from "../../component/Application/ManualApplications/AssetDetail";
import BuyerApplicationDetail from "../../component/Application/ManualApplications/BuyerApplicationDetail";

import moment from "moment/moment";
import { Dropdown } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { components } from "react-select";
import { history } from "../../../_helpers/history";
import { fetchManualApplication } from "../../component/Application/ManualApplications/dealer-api";
import PageNotFoundApp from "../404page/PageNotFoundApp";
import ConfirmDecline from "./ConfirmDecline";
import ConfirmModel from "./ConfirmModel";
import ViewAndSendNotes from "../../../admin/component/Application/ViewAndSendNotes";
const { Option } = components;

const removeFirstDigit = (digits) => {
  const numberString = digits.toString();
  const newNumberString = numberString.slice(1);
  return newNumberString;
}


class EditDealerAdminApplication extends Component {

  constructor(props) {
    super(props);
    this.state = {
      buyerAppId: "",
      url: this.props.match.url,
      applicantFirstName: "",
      applicantLastName: "",
      applicantDateOfBirth: "",
      applicantTelephone: "",
      applicantAddress: "",
      applicantStreetAddress: "",
      applicantStreetAddress2: "",
      applicantLocationName: "",
      applicantCity: "",
      applicantProvince: "",
      selectedProvince: "",
      applicantPostalCode: "",
      employeeBusinessName: "",
      applicantCountry: "",
      employmentStatus: "",
      selectEmploymentStatus: "",
      typeOfEmployment: "",
      selectedTypeOfEmployment: "",
      employerName: "",
      typeOfBusniess: "",
      occupation: "",
      applicantEmailByDealer: "",
      employmentSinceYear: "",
      employmentSinceMonth: "",
      grossMonthlyIncome: 0,
      salutation: "",
      gender: "",
      interestedVehicleType: "",

      // previous applicant address
      applicantPreviousStreetAddress: "",
      applicantPreviousStreetAddress2: "",
      previousCity: "",
      previousProvince: "",
      previousPostalCode: "",
      durationAtPreviousAddress: "",
      durationAtPreviousAddressMonth: "",
      previousStatus: "",
      previousMortgageAmount: "",

      // previous employer details
      previousEmployerName: "",
      previousEmployerStreetAddress: "",
      previousEmployerCity: "",
      previousEmployerProvince: "",
      previousEmployerPhone: "",
      previousEmployerEmail: "",
      previousEmploymentStatus: "",
      previousTypeOfEmployment: "",
      previousEmploymentSinceYear: "",
      previousEmploymentSinceMonth: "",
      previousGrossIncome: 0,
      previousGrossIncomeSlider: 0,
      previousEmployeeBusinessName: "",
      previousEmployeeTypeOfBusiness: "",
      previousOccupation: "",
      genders: [
        { value: "male", label: "Male" },
        { value: "female", label: "Female" },
        { value: "other", label: "Other" },
      ],
      grossMonthlyIncomeValues: [
        { value: "5000", label: "Over $5000" },
        { value: "2500-5000", label: "$2500-5000" },
        { value: "2000-2500", label: "$2000-2500" },
        { value: "2000", label: "Under $2000" }
      ],

      salutations: [
        { value: "Mr.", label: "Mr." },
        { value: "Mrs.", label: "Mrs." },
        { value: "Ms.", label: "Ms." },
      ],
      maritalStatues: [
        { value: "Single", label: "Single" },
        { value: "Married", label: "Married" },
        { value: "Common Law", label: "Common Law" },
        { value: "Separated", label: "Separated" },
        { value: "Divorced", label: "Divorced" },
        { value: "Widowed", label: "Widowed" },
      ],
      applicantMaritalStatus: "",
      provinces: [
        { value: "AB", label: "Alberta" },
        { value: "BC", label: "British Columbia" },
        { value: "MB", label: "Manitoba" },
        { value: "NB", label: "New Brunswick" },
        { value: "NL", label: "Newfoundland and Labrador" },
        { value: "NS", label: "Nova Scotia" },
        { value: "ON", label: "Ontario" },
        { value: "PE", label: "Prince Edward Island" },
        { value: "QC", label: "Quebec" },
        { value: "SK", label: "Saskatchewan" },
        { value: "NT", label: "Northwest Territories" },
        { value: "NU", label: "Nunavut" },
        { value: "YT", label: "Yukon" },
      ],
      statuses: [
        { value: "Rent", label: "Rent" },
        { value: "Other", label: "Other" },
        { value: "Live with Parent", label: "Live with Parent" },
        { value: "Own", label: "Own" },
        { value: "With Parents", label: "With Parents" },
        { value: "Parent", label: "Parent" }
      ],
      employmentStatuses: [
        { label: "Employed", value: "Employed" },
        { label: "Self-Employed", value: "Self-Employed" },
        { label: "Unemployed", value: "Unemployed" },
        { label: "Retired", value: "Retired" },
        { label: "Disability", value: "Disability" },
        { label: "Worker Compensation", value: "Worker Compensation" }
      ],
      typeOfEmployments: [
        { label: "Full Time", value: "Full Time" },
        { label: "Part Time", value: "Part Time" },
        { label: "Seasonal", value: "Seasonal" },
      ],
      vehicleOptions: [],
      conditions: [
        { value: "New", label: "New" },
        { value: "Used", label: "Used" },
      ],
      tradeIns: [
        { value: true, label: "Yes" },
        { value: false, label: "No" },
      ],

      // Other Income
      income_type: "",
      selectedIncomeType: "",
      inComeTypes: [{ value: "Cash", label: "Cash" }, { value: "Cheque", label: "Cheque" }],
      income_amount: "",
      income_frequency: "",
      selectedIncomeFrequency: "",
      inComeFrequencies: [{ value: "Annually", label: "Annually" }, { value: "Monthly", label: "Monthly" }],

      // Asset Detail
      vehicleType: "",
      selectedVehicle: "",
      selectedTradeIn: {
        value: false,
        label: "No"
      },
      tradeIn: false,
      stockNumber: "",
      year: "",
      selectedYear: "",
      make: "",
      selectedMake: "",
      model: "",
      selectedModel: "",
      trim: "",
      selectedTrim: "",
      kilometer: "",
      vin: "",
      price: "",
      condition: "",
      selectedCondition: "",
      sub_type_of_vehicle: "",
      selectedSubTypeOfVehicle: "",
      optionsSubTypeOfVehicle: [],
      body_type: "",
      engine: "",
      tonnage: "",
      selectedTonnage: "",
      length: "",
      client_budget: '',

      postApp: [
        {
          id: 1,
          name: "Applicant Details",
          active: true,
          done: false,
          disbaled: false,
          pending: false,
          path: `${this.props.match.url}/applicant-detail`,
        },
        {
          id: 3,
          name: "Asset Detail",
          active: true,
          disbaled: true,
          done: false,
          pending: false,
          path: `${this.props.match.url}/assets-detail`,
        }
      ],

      step: 1,
      lastStep: 1,
      applicationStatus: 3,
      created_at: "",

      //States
      applicantSin: "",
      applicantEmail: "",
      monthlyAmount: 0,
      stockIndex: "",

      personal_complete: false,
      employement_complete: false,
      assets_complete: false,
      firstTypeOfVehicle: "",

      applicationStatuses: [
        { label: "Conditionally approved", value: "2" },
        { label: "Pre-approved", value: "3" },
        { label: "Approved", value: "5" },
        { label: "Booked", value: "6" },
        { label: "Declined", value: "4" },
        { label: "Withdrawn", value: "7" },
        { label: "Credit Unverified", value: "11" },
        { label: "Credit Unknown", value: "1" },
        { label: "Conditionally Approved For Special Program", value: "8" },
        { label: "Manual", value: "9" }],
      // applicationStatuses: [{ label: 'Decline', value: '4' }, { label: 'Approved', value: '5' }, { label: 'Pre-approved', value: '3' }, { label: 'Conditionally approved', value: '2' }, { label: 'pending', value: 'pending' }, { label: 'Missing Info', value: '1' }],
      selectedApplicationStatus: { value: "manual", label: "manual" },
      selectApplicationStatus: "",
      jumio_status: "",
      jumio_images: [],
      jumio_data: [],
      photo: "",
      status: "",
      duration_address_mn: "",
      duration_address_yr: "",
      mortgage_amount: "",
      mainUrl: this.props.match.url
        .substring(0, this.props.match.url.lastIndexOf("/") + 1)
        .replace(/\/$/, ""),

      tempStatus: "",
      declineReason: "",
      income_verification_json: [],
      income_file: false,
      license: "",
      monthly_budget: "",
      subTypeVehicleOptions: [],
      application_category: "",
      application_type: "",

      errors_personal_detail_form: {
        salutation: '',
        applicantFirstName: '',
        applicantLastName: '',
        applicantDateOfBirth: '',
        applicantTelephone: '',
        applicantMaritalStatus: '',
        applicantEmail: '',
        applicantStreetAddress: '',
        applicantCity: '',
        applicantProvince: '',
        applicantPostalCode: '',
        applicantCountry: '',
        status: '',
        duration_address_yr: '',
        is_personal_detail_form_validated: false
      },

      validation_personal_detail_form: {
        salutation: false,
        applicantFirstName: false,
        applicantLastName: false,
        applicantDateOfBirth: false,
        applicantTelephone: false,
        applicantMaritalStatus: false,
        applicantEmail: false,
        applicantStreetAddress: false,
        applicantCity: false,
        applicantProvince: false,
        applicantPostalCode: false,
        applicantCountry: false,
        status: false,
        duration_address_yr: false
      },

      errors_employment_information_form: {
        employmentStatus: '',
        typeOfEmployment: '',
        employerName: '',
        occupation: '',
        employmentSince: '',
        monthlyGrossIncome: '',
        is_employment_information_form_validated: false
      },

      validation_employment_information_form: {
        employmentStatus: false,
        typeOfEmployment: false,
        employerName: false,
        occupation: false,
        employmentSince: false,
        monthlyGrossIncome: false
      },

      errors_asset_detail_form: {
        vehicleType: '',
        sub_type_of_vehicle: '',
        is_asset_detail_form_validated: false
      },

      validation_asset_detail_form: {
        vehicleType: false,
        sub_type_of_vehicle: false,
      },

      /**
       * Operation mode for application
       * create | edit
       */
      operation_mode: 'create',

      /**
       * forcefull trigger
       */
      trigger_number: 1,

    };
    this.componentRef = React.createRef();
  }

  getLabelByValue = (value, object) => {
    for (let i = 0; i < object.length; i++) {
      if (object[i].value === value) {
        return object[i].label;
      }
    }
    return "";
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  select_object = (value) => {
    return (value === undefined || value.length <= 1) ?
      " " :
      {
        value: value ? this.capitalizeFirstLetter(value) : "",
        label: value ? this.capitalizeFirstLetter(value) : ""
      }
  }

  select_object_including_label_by_value = (value, obj) => {
    return (value.length <= 0) ?
      " " :
      {
        value: value ? this.capitalizeFirstLetter(value) : "",
        label: value ? this.getLabelByValue(value, obj) : ""
      }
  }

  set_trade_in(trade_in) {
    return (trade_in === undefined) ? false : trade_in
  }

  select_trade_in = (trade_in) => {
    return (trade_in === undefined) ?
      {
        value: false,
        label: "No"
      }
      :
      {
        value: trade_in,
        label: trade_in ? "Yes" : "False"
      }
  }

  async componentDidMount(prevProps, prevState) {


    const pathParts = this.props.location.pathname.split('/');
    const endPart = pathParts[pathParts.length - 1];

    if (!['applicant-detail', 'assets-detail', 'view-and-send-notes'].includes(endPart)) {
      history.push(`${this.props.location.pathname}/applicant-detail`)
    }

    this.props.get_vehicle_type();
    this.props.get_addtional_type();
    this.props.get_application_agents();
    this.props.remove_credit_details()


    if (
      !!this.props.match.params?.id
    ) {

      fetchManualApplication(this.props.match.params.id)
        .then(response => {
          if (response.data.success === true) {
            const manualApplicationData = response.data.data;

            const postApp = this.state.postApp;
            postApp[1].disbaled = false;
            this.setState({
              ...this.state,
              postApp,
              created_at: manualApplicationData.created_at,
              buyerAppId: this.props.match.params.id,
              operation_mode: 'edit',
              errors_personal_detail_form: { is_personal_detail_form_validated: true },
              errors_employment_information_form: { is_employment_information_form_validated: true },
              errors_asset_detail_form: { is_asset_detail_form_validated: true },
              salutation: manualApplicationData.salutation,
              selectSalutation: {
                value: manualApplicationData.salutation ? manualApplicationData.salutation : "",
                label: manualApplicationData.salutation ? manualApplicationData.salutation : ""
              },
              applicantFirstName: manualApplicationData.first_name,
              applicantLastName: manualApplicationData.last_name,
              applicantDateOfBirth: new Date(manualApplicationData.date_of_birth + "T00:00:00"),
              applicantTelephone: removeFirstDigit(manualApplicationData.phone_number),
              gender: manualApplicationData.gender,
              selectGender: {
                value: manualApplicationData.gender ? manualApplicationData.gender : "",
                label: manualApplicationData.gender ? this.capitalizeFirstLetter(manualApplicationData.gender) : ""
              },
              applicantMaritalStatus: manualApplicationData.marital_status,
              selectApplicantMaritalStatus: {
                value: manualApplicationData.marital_status ? this.capitalizeFirstLetter(manualApplicationData.marital_status) : "",
                label: manualApplicationData.marital_status ? this.capitalizeFirstLetter(manualApplicationData.marital_status) : ""
              },
              applicantSin: manualApplicationData.sin,
              applicantEmail: manualApplicationData.email,
              applicantStreetAddress: manualApplicationData.street_address,
              applicantCity: manualApplicationData.city,
              applicantProvince: manualApplicationData.province,
              selectedProvince: {
                value: manualApplicationData.province ? this.capitalizeFirstLetter(manualApplicationData.province) : "",
                label: this.getLabelByValue(
                  manualApplicationData.province, this.state.provinces) ?
                  this.getLabelByValue(manualApplicationData.province, this.state.provinces) : ""
              },
              applicantPostalCode: manualApplicationData.postal_code,
              applicantCountry: manualApplicationData.country,
              duration_address_yr: manualApplicationData.years_at_current_address,

              selectedProvince: {
                value: manualApplicationData.province ? this.capitalizeFirstLetter(manualApplicationData.province) : "",
                label: this.getLabelByValue(
                  manualApplicationData.province, this.state.provinces) ?
                  this.getLabelByValue(manualApplicationData.province, this.state.provinces) : ""
              },

              status: this.clean_null(manualApplicationData.status),
              selectedStatus: this.select_object_including_label_by_value(
                this.clean_null(manualApplicationData.status),
                this.state.statuses
              ),
              mortgage_amount: manualApplicationData.amount,
              previousCity: manualApplicationData.previous_applicant_address.city,
              durationAtPreviousAddressMonth: manualApplicationData.previous_applicant_address.duration_address_mn,
              durationAtPreviousAddress: manualApplicationData.previous_applicant_address.duration_address_yr,
              previousMortgageAmount: manualApplicationData.previous_applicant_address.mortgage_amount,
              previousPostalCode: manualApplicationData.previous_applicant_address.postal_code,
              previousProvince: manualApplicationData.previous_applicant_address.province,
              selectedPreviousProvince: this.select_object(manualApplicationData.previous_applicant_address?.province),
              previousStatus: this.clean_null(manualApplicationData.previous_applicant_address?.status || ''),
              previousSelectedStatus: this.select_object_including_label_by_value(
                this.clean_null(manualApplicationData.previous_applicant_address?.status || ""),
                this.state.statuses
              ),
              applicantPreviousStreetAddress: manualApplicationData.previous_applicant_address.street_address,

              employmentStatus: manualApplicationData.employement_status,
              selectEmploymentStatus: this.select_object(manualApplicationData.employement_status),
              typeOfEmployment: manualApplicationData.type_of_employment,
              selectedTypeOfEmployment: this.select_object(manualApplicationData.type_of_employment),
              employerName: manualApplicationData.employer_name,
              occupation: manualApplicationData.occupation,
              employmentSince: new Date(manualApplicationData.employment_since + "T00:00:00"),
              employerStreetAddress: manualApplicationData.employer_address,
              employerCity: manualApplicationData.employer_city,
              employerPhone: removeFirstDigit(manualApplicationData.employer_telephone),
              employerProvince: manualApplicationData.employer_province,
              selectedEmployerProvince: this.select_object_including_label_by_value(
                this.clean_null(manualApplicationData.employer_province),
                this.state.provinces
              ),
              employerEmail: manualApplicationData.employer_email,
              monthlyGrossIncome: manualApplicationData.monthly_income,
              monthlySelectedGrossIncome: {
                value: manualApplicationData.monthly_income ? this.capitalizeFirstLetter(manualApplicationData.monthly_income) : "",
                label: this.getLabelByValue(
                  manualApplicationData.monthly_income, this.state.grossMonthlyIncomeValues) ?
                  this.getLabelByValue(manualApplicationData.monthly_income, this.state.grossMonthlyIncomeValues) : ""
              },

              income_type: manualApplicationData.other_income?.income_type || "",
              income_amount: manualApplicationData.other_income?.income_amount || "",
              income_frequency: manualApplicationData.other_income?.income_frequency || "",
              selectedIncomeFrequency: this.select_object(manualApplicationData.other_income?.income_frequency),

              vehicleType: manualApplicationData.vehicle_type,
              selectedVehicle: this.select_object(manualApplicationData.vehicle_type),
              sub_type_of_vehicle: manualApplicationData.sub_type_of_vehicle,
              selectedSubTypeOfVehicle: this.select_object(manualApplicationData.sub_type_of_vehicle),
              monthly_budget: manualApplicationData.monthly_budget,
              selectedMonthlyBudget: this.select_object(manualApplicationData.monthly_budget),
              body_type: manualApplicationData.vehicle.body_type,
              year: manualApplicationData.vehicle.year,
              make: manualApplicationData.vehicle.make,
              model: manualApplicationData.vehicle.model,
              trim: manualApplicationData.vehicle.trim,
              tradeIn: this.set_trade_in(manualApplicationData.vehicle.trade_in),
              selectedTradeIn: this.select_trade_in(manualApplicationData.vehicle.trade_in),
              stockNumber: manualApplicationData.vehicle.stock_id,
              engine: manualApplicationData.vehicle.engine,
              kilometer: manualApplicationData.vehicle.kilometer,
              vin: manualApplicationData.vehicle.vin,
              price: manualApplicationData.vehicle.price,
              condition: manualApplicationData.vehicle.condition,
              selectedCondition: this.select_object(manualApplicationData.vehicle.condition),
            })
          }
        }).catch((e) => {
          console.log('API_SERVICE ERROR ', e);
        })
    }

    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
    Geocode.setLanguage("en");
    Geocode.setRegion("es");

    let currentYear = new Date().getFullYear();
    let earliestYear = 1900;
    let years = [];
    while (currentYear >= earliestYear) {
      years.push({ label: `${currentYear}`, value: currentYear });
      currentYear -= 1;
    }

    let lastStep = 1;
    let coApplicant = false;
    if (localStorage.getItem("lastStepPostAppEditAdmin")) {
      lastStep = localStorage.getItem("lastStepPostAppEditAdmin");
    }

    this.setState({
      ...this.state,
      yearsDropDown: years,
      lastStep: lastStep,
      coApplicant: coApplicant,
      postApp: this.state.postApp.slice().map((item) => {
        if (item.path === '/dealer-admin/application/addForm/applicant-detail') {
          return {
            ...item,
            active: true,
          };
        }
        return {
          ...item,
          active: false,
        };
      }),
    });

  }

  handleOnChangeYears = (e, name) => {
    this.personal_detail_validation(name, e.value)
    this.setState({ ...this.state, [name]: e.value })
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    if (name === "interestRate") {
      const interestRate = value
        .toString()
        .split(",")
        .join("")
        .split("%")
        .join("");
      this.setState({
        ...this.state,
        [name]: interestRate,
      });
    } else {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  /** Common functions */
  is_empty(string) {
    if (typeof string === 'string' && string.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  hasMethod(subject, methodName) {
    return subject != null && typeof subject[methodName] == "function";
  }

  /** Personal detail form validation : start */
  personal_detail_validation = (name, value) => {

    let errors = this.state.errors_personal_detail_form;
    let _value = value ? value == null : ''

    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    switch (name) {

      case 'salutation':
        errors.salutation =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.salutation)) {
          this.state.validation_personal_detail_form.salutation = true
        }
        break;

      case 'applicantFirstName':
        errors.applicantFirstName =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.applicantFirstName)) {
          this.state.validation_personal_detail_form.applicantFirstName = true
        }
        break;

      case 'applicantLastName':
        errors.applicantLastName =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.applicantLastName)) {
          this.state.validation_personal_detail_form.applicantLastName = true
        }
        break;

      case 'applicantDateOfBirth':
        errors.applicantDateOfBirth =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.applicantFirstName)) {
          this.state.validation_personal_detail_form.applicantDateOfBirth = true
        }
        break;

      case 'applicantTelephone':
        errors.applicantTelephone =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.applicantTelephone)) {
          this.state.validation_personal_detail_form.applicantTelephone = true
        }
        break;

      case 'applicantMaritalStatus':
        errors.applicantMaritalStatus =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.applicantMaritalStatus)) {
          this.state.validation_personal_detail_form.applicantMaritalStatus = true
        }
        break;

      case 'applicantEmail':
        errors.applicantEmail =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.applicantEmail)) {
          this.state.validation_personal_detail_form.applicantEmail = true
        }
        break;

      case 'applicantStreetAddress':
        errors.applicantStreetAddress =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.applicantStreetAddress)) {
          this.state.validation_personal_detail_form.applicantStreetAddress = true
        }
        break;

      case 'applicantStreetAddress2':
        errors.applicantStreetAddress2 =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.applicantStreetAddress2)) {
          this.state.validation_personal_detail_form.applicantStreetAddress2 = true
        }
        break;

      case 'applicantCity':
        errors.applicantCity =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.applicantCity)) {
          this.state.validation_personal_detail_form.applicantCity = true
        }
        break;

      case 'applicantProvince':
        errors.applicantProvince =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.applicantProvince)) {
          this.state.validation_personal_detail_form.applicantProvince = true
        }
        break;
      case 'applicantPostalCode':
        errors.applicantPostalCode =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.applicantPostalCode)) {
          this.state.validation_personal_detail_form.applicantPostalCode = true
        }
        break;
      case 'applicantCountry':
        errors.applicantCountry =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.applicantCountry)) {
          this.state.validation_personal_detail_form.applicantCountry = true
        }
        break;

      case 'duration_address_yr':
        errors.duration_address_yr =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.duration_address_yr)) {
          this.state.validation_personal_detail_form.duration_address_yr = true
        }
        break;

      case 'status':
        errors.status =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.status)) {
          this.state.validation_personal_detail_form.status = true
        }
        break;
      case 'mortgage_amount':
        errors.mortgage_amount =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.mortgage_amount)) {
          this.state.validation_personal_detail_form.mortgage_amount = true
        }
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });

  }

  handleOnChangePersonalDetail = (event) => {
    if (this.hasMethod(event, "preventDefault")) {
      event.preventDefault();
    }
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
    this.personal_detail_validation(name, value)
  }

  validateOnChangePersonalDetail = (name, value) => {
    this.personal_detail_validation(name, value)
  }

  isString(val) {
    return typeof val === 'string' || ((!!val && typeof val === 'object') && Object.prototype.toString.call(val) === '[object String]');
  }

  /** Employment information form validation : start */
  employment_information_validation = (name, value) => {

    let errors = this.state.errors_employment_information_form;
    let _value = (value == null) ? '' : value
    let employment_statuses = ["Unemployed", "Retired"]

    switch (name) {

      case 'employmentStatus':
        errors.employmentStatus =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.employmentStatus)) {
          this.state.validation_employment_information_form.employmentStatus = true
        }
        break;

      case 'typeOfEmployment':
        errors.typeOfEmployment =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';

        if (this.is_empty(errors.typeOfEmployment)) {
          this.state.validation_employment_information_form.typeOfEmployment = true
        } else {
          this.state.validation_employment_information_form.typeOfEmployment = false
        }
        if (employment_statuses.includes(this.state.employmentStatus)) {
          this.state.validation_employment_information_form.typeOfEmployment = true
        }
        break;

      case 'employerName':
        errors.employerName =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.employerName)) {
          this.state.validation_employment_information_form.employerName = true
        } else {
          this.state.validation_employment_information_form.employerName = false
        }
        if (employment_statuses.includes(this.state.employmentStatus)) {
          this.state.validation_employment_information_form.employerName = true
        }
        break;

      case 'occupation':
        errors.occupation =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.occupation)) {
          this.state.validation_employment_information_form.occupation = true
        } else {
          this.state.validation_employment_information_form.occupation = false
        }
        if (employment_statuses.includes(this.state.employmentStatus)) {
          this.state.validation_employment_information_form.occupation = true
        }
        break;

      case 'employmentSince':
        errors.employmentSince =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.employmentSince)) {
          this.state.validation_employment_information_form.employmentSince = true
        } else {
          this.state.validation_employment_information_form.employmentSince = false
        }
        if (employment_statuses.includes(this.state.employmentStatus)) {
          this.state.validation_employment_information_form.employmentSince = true
        }
        break;

      case 'monthlyGrossIncome':
        errors.monthlyGrossIncome =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.monthlyGrossIncome)) {
          this.state.validation_employment_information_form.monthlyGrossIncome = true
        } else {
          this.state.validation_employment_information_form.monthlyGrossIncome = false
        }
        if (employment_statuses.includes(this.state.employmentStatus)) {
          this.state.validation_employment_information_form.monthlyGrossIncome = true
        }
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  }

  clean_null = (str) => {
    let convert_to_str = String(str);
    let cleanable_words = ["null", "undefined"]
    return cleanable_words.includes(convert_to_str) ? "" : convert_to_str.replace(/^\s+|\s+$/g, '')
  }

  handleOnChangeEmploymentInformation = (event) => {

    if (this.hasMethod(event, "preventDefault")) {
      event.preventDefault();
    }
    const { name, value } = event.target;

    this.setState({
      ...this.state,
      [name]: value,
    });

    this.employment_information_validation(name, value)
  };

  validateOnChangeEmploymentInformation = (name, value) => {
    this.employment_information_validation(name, value)
  }

  /** Asset detail form validation */
  asset_detail_validation = (name, value) => {

    let errors = this.state.errors_asset_detail_form;
    let _value = value ? value == null : ''

    switch (name) {
      case 'vehicleType':
        errors.vehicleType =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.vehicleType)) {
          this.state.validation_asset_detail_form.vehicleType = true
        }
        break;
      case 'sub_type_of_vehicle':
        errors.sub_type_of_vehicle =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.sub_type_of_vehicle)) {
          this.state.validation_asset_detail_form.sub_type_of_vehicle = true
        }
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });

    const categories = [7, 9, 6, 3, 10]
    if (!categories.includes(this.state.vehicleType)) {
      errors.sub_type_of_vehicle = ''
      this.state.validation_asset_detail_form.sub_type_of_vehicle = true
    }
  }

  validateOnChangeAssetDetail = (name, value) => {
    this.asset_detail_validation(name, value)
  }

  handleOnChangeAssetDetail = (event) => {
    let cst = this.state.lastStep + 1
    this.setState({ ...this.state, ["lastStep"]: cst })

    if (this.hasMethod(event, "preventDefault")) {
      event.preventDefault();
    }
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });

    this.asset_detail_validation(name, value)
  };

  handleOnChangeDates = (e, name) => {

    var personal_detail = ['applicantDateOfBirth']
    var employment_information = ['employmentSince']

    if (personal_detail.includes(name)) {
      this.personal_detail_validation(name, e)
    }

    if (employment_information.includes(name)) {
      this.employment_information_validation(name, e)
    }

    this.setState({
      ...this.state,
      [name]: e,
    });

  };

  padTo2Digits = num => {
    return num.toString().padStart(2, '0')
  }

  handleOnChangeDate = (e, name) => {

    var personal_detail = ['applicantDateOfBirth']
    var employment_information = ['employmentSince']

    if (personal_detail.includes(name)) {
      this.personal_detail_validation(name, e)
    }

    if (employment_information.includes(name)) {
      this.employment_information_validation(name, e)
    }

    this.setState({
      ...this.state,
      [name]: e,
    });

  };

  handleLocationChange = (e, name) => {
    this.setState({
      ...this.state,
      [name]: e,
    });
  };

  setSubTypeOfVehicle = (_sub_type_of_vehicle) => {
    this.state.optionsSubTypeOfVehicle = []
    _sub_type_of_vehicle.map((row) => {
      this.state.optionsSubTypeOfVehicle.push({ value: row.name, label: row.name })
    })
  }

  changeSelect = (e, formName, name) => {

    var personal_detail = ['selectSalutation', 'selectGender', 'selectApplicantMaritalStatus', 'selectedProvince', 'selectedStatus', 'selectedPreviousProvince', 'previousSelectedStatus']
    var employment_information = ['selectEmploymentStatus', 'monthlySelectedGrossIncome', 'selectedTypeOfEmployment', 'selectedIncomeFrequency']
    var asset_detail = ['selectedVehicle', 'selectedSubTypeOfVehicle', 'selectedMonthlyBudget', 'selectedIn', 'selectedTradeIn']

    if (personal_detail.includes(name)) {
      this.personal_detail_validation(formName, e.value)
    }

    if (employment_information.includes(name)) {
      this.employment_information_validation(formName, e.value)
    }

    if (asset_detail.includes(name)) {
      if (name == 'selectedVehicle') {
        this.state.sub_type_of_vehicle = ''
        this.state.selectedSubTypeOfVehicle = ''
        this.state.validation_asset_detail_form.sub_type_of_vehicle = false
        this.setSubTypeOfVehicle(e.sub_type_of_vehicle)
      }
      this.asset_detail_validation(formName, e.value)
    }

    this.setState({
      ...this.state,
      [name]: e,
      [formName]:
        e !== undefined && e !== null
          ? e.value !== undefined && e.value !== null
            ? e.value
            : ""
          : "",
    });

  };


  componentDidUpdate(prevProps, prevState) {

    if (
      prevProps.stock_detail !== this.props.stock_detail &&
      this.props.stock_detail &&
      this.props.stock_detail.length > 0
    ) {
      this.props.stock_detail.map((item) => {

        this.state.vehicleType = item.category ? item.category.name : ""
        this.state.selectedVehicle = {
          value: item.category ? item.category.name : "",
          label: item.category ? item.category.name : ""
        }
        this.state.sub_type_of_vehicle = item.sub_type_id ? item.sub_type_id.name : ""
        this.state.selectedSubTypeOfVehicle = {
          value: item.sub_type_id ? item.sub_type_id.name : "",
          label: item.sub_type_id ? item.sub_type_id.name : ""
        }
        this.state.tradeIn = this.set_trade_in(item.trade_in)
        this.state.selectedTradeIn = this.select_trade_in(item.trade_in)

        this.state.stockNumber = item.stock_id
        this.state.year = this.clean_null(item.year)
        this.state.make = this.clean_null(item.make?.make_name)
        this.state.model = this.clean_null(item.model?.model_make)
        this.state.trim = this.clean_null(item.trim)
        this.state.kilometer = this.clean_null(item.kilometer)
        this.state.vin = this.clean_null(item.vin)
        this.state.price = this.clean_null(item.price)
        this.state.condition = this.clean_null(item.v_condition)
        this.state.selectedCondition = item.v_condition
          ? { value: item.v_condition, label: item.v_condition }
          : ""
        this.state.body_type = item.body_type ? item.body_type.body_type : ""
        this.state.engine = this.clean_null(item.engine)
        this.state.tonnage = this.clean_null(item.tonnage)
        this.state.selectedTonnage = this.clean_null(item.selectedTonnage)
        this.state.length = this.clean_null(item.length)
        this.state.client_budget = parseFloat(this.clean_null(item.client_budget)) || 0
      })

      this.setState({ trigger_number: this.state.trigger_number + 1 });
    }

    if (
      prevProps.type_of_vehicles !== this.props.type_of_vehicles &&
      this.props.type_of_vehicles !== undefined
    ) {
      const typeOfVehicle = [];

      (this.props.type_of_vehicles || []).map((item, index) => {
        typeOfVehicle.push({
          value: item.name,
          label: item.name,
          sub_type_of_vehicle: item.typeofvehicle_id
        });
      });
      const subTypeOfVehicle = [];
      (this.props.type_of_vehicles || []).filter(item => +item.id === 9)?.[0]?.typeofvehicle_id?.map((item, index) => {
        subTypeOfVehicle.push({
          value: item.name,
          label: item.name,
        });
      });
      let firstTypeOfVehicle = this.props.application_detail
        ? this.props.application_detail.vehicle &&
          this.props.application_detail.vehicle.length > 0
          ? (this.props.application_detail.vehicle || [])[0]
            ? typeOfVehicle
              .filter(
                (item) =>
                  Number(item.value) ===
                  Number(
                    (this.props.application_detail.vehicle || [])[0]
                      .type_of_vehicle
                  )
              )
              .map((item) => {
                return item.label;
              })
            : ""
          : ""
        : "";

      this.setState({
        ...this.state,
        vehicleOptions: typeOfVehicle,
        subTypeVehicleOptions: subTypeOfVehicle,
        firstTypeOfVehicle:
          firstTypeOfVehicle && firstTypeOfVehicle.length > 0
            ? firstTypeOfVehicle[0]
            : ""
      })
    }

  }


  onClickChangeStep = (prev, step) => {
    if (step === 2) {
      localStorage.setItem("coApplicantEditPostAppAdmin", true);
      this.setState({
        ...this.state,
        step: step,
        coApplicant: true,
        postApp: this.state.postApp.slice().map((item) => {
          if (Number(item.id) === Number(prev)) {
            return {
              ...item,
              active: false,
              disbaled: false,
            };
          }
          if (Number(item.id) === Number(step)) {
            return {
              ...item,
              active: true,
              disbaled: false,
            };
          }
          // return item
          return {
            ...item,
            active: false,
          };
        }),
      });
    } else {
      this.setState({
        ...this.state,
        step: step,
        postApp: this.state.postApp.slice().map((item) => {
          if (Number(item.id) === Number(prev)) {
            return {
              ...item,
              active: false,
              disbaled: false,
            };
          }
          if (Number(item.id) === Number(step)) {
            return {
              ...item,
              active: item.id !== 2 ? true : this.state.coApplicant,
              disbaled: false,
            };
          }
          // return item
          return {
            ...item,
            active: false,
          };
        }),
      });
    }
  };

  onClickChangeNav = (step) => {
    if (step === 2) {
      localStorage.setItem("coApplicantEditPostApp", true);
      this.setState({
        ...this.state,
        step: step,
        coApplicant: true,
        postApp: this.state.postApp.slice().map((item) => {
          if (Number(item.id) === Number(step)) {
            return {
              ...item,
              active: true,
              disbaled: false,
            };
          }
          if (
            Number(item.id) === Number(this.state.step) &&
            Number(this.state.step) !== Number(step)
          ) {
            return {
              ...item,
              active: false,
              disbaled: false,
            };
          }
          // return item
          return {
            ...item,
            active: false,
          };
        }),
      });
    } else {
      this.setState({
        ...this.state,
        step: step,
        postApp: this.state.postApp.slice().map((item) => {
          if (Number(item.id) === Number(step)) {
            return {
              ...item,
              active: true,
              disbaled: false,
            };
          }
          if (
            Number(item.id) === Number(this.state.step) &&
            Number(this.state.step) !== Number(step)
          ) {
            return {
              ...item,
              active: false,
              disbaled: false,
            };
          }
          // return item
          return {
            ...item,
            active: false,
          };
        }),
      });
    }
  };

  onClickChangeStep = (prev, step) => {
    if (step === 2) {
      localStorage.setItem("coApplicantEditPostAppAdmin", true);
      this.setState({
        ...this.state,
        step: step,
        coApplicant: true,
        postApp: this.state.postApp.slice().map((item) => {
          if (Number(item.id) === Number(prev)) {
            return {
              ...item,
              active: false,
              disbaled: false,
            };
          }
          if (Number(item.id) === Number(step)) {
            return {
              ...item,
              active: true,
              disbaled: false,
            };
          }
          // return item
          return {
            ...item,
            active: false,
          };
        }),
      });
    } else {
      this.setState({
        ...this.state,
        step: step,
        postApp: this.state.postApp.slice().map((item) => {
          if (Number(item.id) === Number(prev)) {
            return {
              ...item,
              active: false,
              disbaled: false,
            };
          }
          if (Number(item.id) === Number(step)) {
            return {
              ...item,
              active: item.id !== 2 ? true : this.state.coApplicant,
              disbaled: false,
            };
          }
          // return item
          return {
            ...item,
            active: false,
          };
        }),
      });
    }
  };

  onClickChangeNav = (step) => {
    if (step === 2) {
      localStorage.setItem("coApplicantEditPostApp", true);
      this.setState({
        ...this.state,
        step: step,
        coApplicant: true,
        postApp: this.state.postApp.slice().map((item) => {
          if (Number(item.id) === Number(step)) {
            return {
              ...item,
              active: true,
              disbaled: false,
            };
          }
          if (
            Number(item.id) === Number(this.state.step) &&
            Number(this.state.step) !== Number(step)
          ) {
            return {
              ...item,
              active: false,
              disbaled: false,
            };
          }
          // return item
          return {
            ...item,
            active: false,
          };
        }),
      });
    } else {
      this.setState({
        ...this.state,
        step: step,
        postApp: this.state.postApp.slice().map((item) => {
          if (Number(item.id) === Number(step)) {
            return {
              ...item,
              active: true,
              disbaled: false,
            };
          }
          if (
            Number(item.id) === Number(this.state.step) &&
            Number(this.state.step) !== Number(step)
          ) {
            return {
              ...item,
              active: false,
              disbaled: false,
            };
          }
          // return item
          return {
            ...item,
            active: false,
          };
        }),
      });
    }
  };

  emptyFun = () => {
    return true;
  };

  handleOnBlurStock = (id) => {

    if (id) {
      this.props.get_stock_id_detail(id);
      this.state.trigger_number = 100;
    }
  };

  changeSelectStatus = (e, formName, name, stateName, prevState) => {
    if (Number(prevState) !== Number(e.value)) {
      if (Number(e.value) === 4) {
        window.$("#confirmDecline").modal("show");
      } else {
        window.$("#updateStatusModel").modal("show");
      }

      this.setState({
        ...this.state,
        tempStatus: e,
      });
    } else {
      return false;
    }
  };

  update_application_status = (e) => {
    this.setState({
      ...this.state,
      selectedApplicationStatus: e,
      applicationStatus:
        e !== undefined && e !== null
          ? e.value !== undefined && e.value !== null
            ? e.value
            : ""
          : "",
      selectApplicationStatus:
        e !== undefined && e !== null
          ? e.value !== undefined && e.value !== null
            ? e.value
            : ""
          : "",
    });
  };

  update_application_decline = (e, declineReason) => {
    this.setState({
      ...this.state,
      selectedApplicationStatus: e,
      applicationStatus:
        e !== undefined && e !== null
          ? e.value !== undefined && e.value !== null
            ? e.value
            : ""
          : "",
      selectApplicationStatus:
        e !== undefined && e !== null
          ? e.value !== undefined && e.value !== null
            ? e.value
            : ""
          : "",
      declineReason: declineReason,
    });
  };

  changeCompleteStatus = (e, name) => {
    this.setState({
      ...this.state,
      [name]: !this.state[name],
    });
    const data = {
      id: this.state.buyerAppId,
      [name]: !this.state[name],
      additional_item: [],
    };

    this.props.update_application_detail_complete(data, name);
  };

  onDrop = async (files, id) => {

    this.setState({
      ...this.state,
      imageErrors: [],
    });
    let index = 0;
    let width = 141.8;
    let height = 107.31;



    let emptyPreviewFiles = this.state.vehicleImages.filter(
      (item) => item.preViewFiles === null && item.path === ""
    );
    let fillPreviewFiles = this.state.vehicleImages.filter(
      (item) => item.preViewFiles !== null || item.path !== ""
    );
    let emptyFiles = this.state.vehicleUploadImages.filter(
      (item) => item.files === null && item.path === ""
    );
    let fillFiles = this.state.vehicleUploadImages.filter(
      (item) => item.files !== null || item.path !== ""
    );
    for (const item of files) {
      if (
        item.type != "image/png" &&
        item.type != "image/jpg" &&
        item.type != "image/jpeg"
      ) {
        this.setState({
          ...this.state,
          imageErrors: [
            ...this.state.imageErrors,
            {
              message: `${item.path} File does not support. You must use .png, jpeg or .jpg`,
            },
          ],
        });
      } else {
        if (item.size > 2 * 1024 * 1024) {
          this.setState({
            ...this.state,
            imageErrors: [
              ...this.state.imageErrors,
              {
                message: `${item.path} Please upload a file smaller than 2 MB`,
              },
            ],
          });
        } else {
          emptyPreviewFiles = emptyPreviewFiles.map((img, fileIndex) => {
            if (fileIndex === index) {

              return {
                ...img,
                preViewFiles: Object.assign(item, {
                  preview: URL.createObjectURL(item),
                }),
              };
            }
            return img;
          });
          emptyFiles = emptyFiles.map((img, fileIndex) => {
            if (fileIndex === index) {
              return {
                ...img,
                files: item,
              };
            }
            return img;
          });

          index++;
        }

        this.setState({
          ...this.state,
          vehicleUploadImages: [...(fillFiles || []), ...emptyFiles],
          vehicleImages: [...(fillPreviewFiles || []), ...emptyPreviewFiles],
        });
      }
    }
  };

  removeFile = (id) => {
    const fileExist = this.state.vehicleImages
      .filter((item) => Number(item.id) === Number(id))
      .map((item) => {
        return item.path;
      })[0];
    if (fileExist !== undefined && fileExist !== null && fileExist !== "") {
      const data = {
        update: "remove_images",
        rimg_len: 1,
        "rimages[0]": fileExist,
      };
      // props.delete_listing_image(data);
    }

  };

  render() {
    var result = this.props.match.url.split("/");
    var Param = result[result.length - 2];
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {Param
              ? Param === "pending"
                ? "Pending Application"
                : "Active Application"
              : "Application"}
          </title>
          <meta name="description" content="" />
        </Helmet>
        <div className="app-form-main clearfix">
          <Link className="back" to={`/dealer-admin/application/general`}>
            {" "}
            Back to applications{" "}
          </Link>
          <div className="app-form-side-bar">
            <div className="side-bar-content">
              <div className="sidebar-head clearfix">
                <div className="row date-Id" style={{ color: '#fff' }}>
                  <div className="col-6"><span>
                    {
                      this.state.operation_mode === 'edit' ? moment(this.state.created_at).format("ll") : ''
                    }
                  </span>
                  </div>
                  {
                    this.state.operation_mode === 'edit' ?
                      <div className="col-6" ><span className="float-right">Application ID: {this.state.buyerAppId}</span></div>
                      : ''
                  }

                </div>
                {this.props.application_detail ? this.props.application_detail.score ?
                  <div className="col-md-6 pl-3">
                    <div className="credit_btnn">
                      Credit Tier: 0{this.getScoreTier(this.props.application_detail.score)}
                    </div>
                  </div>
                  : null : null}
                <div className="application-status" id="dropdown-basic">
                  <p> Application Status </p>
                  {
                    this.state.operation_mode === 'edit' ?
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id=""
                          className={
                            Number(this.state.applicationStatus) === Number("1")
                              ? "status Credit Unknown"
                              : Number(this.state.applicationStatus) === Number("2")
                                ? "status conditionally-approved"
                                : Number(this.state.applicationStatus) === Number("3")
                                  ? "status pre-approved"
                                  : Number(this.state.applicationStatus) === Number("4")
                                    ? "status declinded"
                                    : Number(this.state.applicationStatus) === Number("5")
                                      ? "status approved"
                                      : Number(this.state.applicationStatus) === Number("6")
                                        ? "status funded"
                                        : Number(this.state.applicationStatus) === Number("7")
                                          ? "status withdraw"
                                          : "status Credit Unknown"
                          }
                        >
                          {this.state.selectedApplicationStatus
                            ? this.state.selectedApplicationStatus.label
                              ? this.state.selectedApplicationStatus.label
                              : ""
                            : ""}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {this.state.applicationStatuses.map((item, index) => (
                            <Dropdown.Item
                              onClick={() =>
                                this.changeSelectStatus(
                                  { value: item.value, label: item.label },
                                  "applicationStatus",
                                  "selectedApplicationStatus",
                                  "selectApplicationStatus",
                                  this.state.applicationStatus
                                )
                              }
                              key={index}
                            >
                              {item.label}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                      : ''
                  }

                </div>
                <div className="row report-btn py-3" style={{
                  background: "#3f2355",
                  marginTop: "10px",
                }}>
                  <div className="col-md-12 viewSend-Note-Btn">
                    <Link
                      to={`${this.props.match.url}/view-and-send-notes`}
                    >
                      <img src="/assets/image/notes-icon.svg" /> View and Send Notes
                    </Link>
                  </div>
                </div>
              </div>

              <div className="sidebar-nav-holder">
                <ul className="nav-list">
                  {(this.state.postApp || []).map((item, index) => (
                    <li
                      className={
                        item.disbaled
                          ? "nav-list-item not_done"
                          : item.done === true
                            ? "nav-list-item done"
                            : item.pending === true
                              ? "nav-list-item already_upload"
                              : item.active === true
                                ? "nav-list-item active"
                                : "nav-list-item not_done"
                      }
                      key={index}
                      onClick={
                        item.disbaled
                          ? this.emptyFun
                          : () => this.onClickChangeNav(item.id)
                      }
                    >
                      {" "}
                      <Link
                        className="nav-link"
                        to={item.disbaled ? "#" : item.path}
                      >
                        {" "}
                        <span className="status-circle">
                          {" "}
                          {item.disbaled ? (
                            <i className="fa fa-check"></i>
                          ) : item.done === true ? (
                            <i className="fa fa-check"></i>
                          ) : item.pending === true ? (
                            <img
                              src="/assets/image/applicationClock.svg"
                              alt="img"
                              className="mb-1"
                            />
                          ) : item.active ? (
                            <i
                              className="fa fa-check"
                              style={{ color: "#fff" }}
                            ></i>
                          ) : (
                            <i className="fa fa-check"></i>
                          )}{" "}
                        </span>{" "}
                        {item.name}{" "}
                      </Link>{" "}
                      {item.disbaled ||
                        (item.done === true ? (
                          <span className="status-tag verified">Verified</span>
                        ) : (
                          item.pending && (
                            <span className="status-tag pending">
                              Pending Review
                            </span>
                          )
                        ))}
                    </li>
                  ))}
                </ul>

              </div>
              <div className="image-holder">
                <img src="/assets/image/sidebar-bottom-image.svg" alt="" />
              </div>
            </div>
          </div>
          <Switch>

            <Route
              path={`${this.props.match.url}`}
              exact
              name="Buyer Application Detail"
              render={(props) => (
                <BuyerApplicationDetail
                  addAppLication={true}
                  handleOnChange={this.handleOnChange}
                  {...this.state}
                  handleOnChangeYears={this.handleOnChangeYears}
                  handleOnChangeDates={this.handleOnChangeDates}
                  handleLocationChange={this.handleLocationChange}
                  changeSelect={this.changeSelect}
                  onClickChangeStep={this.onClickChangeStep}
                  {...this.props}
                  handleChangeSlider={this.handleChangeSlider}
                  changeCompleteStatus={this.changeCompleteStatus}
                />
              )}
            />

            <Route
              path={`${this.props.match.url}/applicant-detail`}
              exact
              name="Buyer Application Detail"
              render={(props) => (
                <BuyerApplicationDetail
                  handleOnChange={this.handleOnChange}
                  handleOnChangePersonalDetail={this.handleOnChangePersonalDetail}
                  handleOnChangeEmploymentInformation={this.handleOnChangeEmploymentInformation}
                  validateOnChangePersonalDetail={this.validateOnChangePersonalDetail}
                  validateOnChangeEmploymentInformation={this.validateOnChangeEmploymentInformation}
                  {...this.state}
                  addAppLication={true}
                  handleOnChangeYears={this.handleOnChangeYears}
                  handleOnChangeDates={this.handleOnChangeDates}
                  handleOnChangeDate={this.handleOnChangeDate}
                  handleLocationChange={this.handleLocationChange}
                  changeSelect={this.changeSelect}
                  onClickChangeStep={this.onClickChangeStep}
                  {...this.props}
                  handleChangeSlider={this.handleChangeSlider}
                  changeCompleteStatus={this.changeCompleteStatus}
                  onClickChangeNav={this.onClickChangeNav}
                />
              )}
            />

            <Route
              path={`${this.props.match.url}/assets-detail`}
              exact
              name="Assets Detail"
              render={(props) => (
                <AssetDetail
                  handleOnChange={this.handleOnChange}
                  handleOnChangeAssetDetail={this.handleOnChangeAssetDetail}
                  validateOnChangeAssetDetail={this.validateOnChangeAssetDetail}
                  {...this.state}
                  addAppLication={true}
                  {...this.props}
                  addAssets={this.addAssets}
                  deleteAsset={this.deleteAsset}
                  changeSelect={this.changeSelect}
                  handleLocationChange={this.handleLocationChange}
                  onClickChangeStep={this.onClickChangeStep}
                  handleOnBlurStock={this.handleOnBlurStock}
                  changeCompleteStatus={this.changeCompleteStatus}
                />
              )}
            />

            <Route
              path={`${this.props.match.url}/view-and-send-notes`}
              exact
              name=""
              render={(props) => (
                <ViewAndSendNotes
                  {...this.state}
                  {...this.props}
                  showHeader={true}
                />
              )}
            />

            <Route
              name="404 Not Found"
              path={`${this.props.match.url}/*`}
              component={PageNotFoundApp} />
          </Switch>

          <ConfirmModel
            tempSelect={this.state.tempStatus}
            update_application_status={this.update_application_status}
            heading={"Application Status"}
            section1={"Do you really want to change application status"}
            section2=""
          />
          <ConfirmDecline
            declineReason={this.state.declineReason}
            tempSelect={this.state.tempStatus}
            update_application_decline={this.update_application_decline}
            heading={"Application Decline"}
          />
        </div>
      </React.Fragment>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    type_of_vehicles: state.adPostReducers.addPostReducer.type_of_vehicle,
    addtional_types:
      state.dealerAdminReducer.dealerApplicationReducer.addtional_types,
    application_detail:
      state.dealerAdminReducer.dealerApplicationReducer.application_detail,
    application_manual_detail:
      state.dealerAdminReducer.dealerApplicationReducer.application_manual_detail,
    vehicle_makes:
      state.dealerAdminReducer.dealerApplicationReducer.vehicle_makes,
    vehicle_models:
      state.dealerAdminReducer.dealerApplicationReducer.vehicle_models,
    vehicle_trims:
      state.dealerAdminReducer.dealerApplicationReducer.vehicle_trims,
    stock_detail:
      state.dealerAdminReducer.dealerApplicationReducer.stock_detail,
    application_credit_detail: state.adminReducer.adminAccounts.applicationReducer.application_credit_detail
  };
};

export default connect(mapStateToProps, {
  get_vehicle_type,
  get_addtional_type,
  remove_credit_details,
  get_application_detail,
  get_manual_application_detail,
  get_vehicle_make,
  get_vehicle_model,
  get_vehicle_trims,
  update_application_detail,
  get_stock_id_detail,
  update_application_detail_complete,
  get_application_agents
})(EditDealerAdminApplication);